import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js'
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js'
import FeaturedImage from 'images/section-03-bg.svg'
import { SectionDescription } from 'components/misc/Typography.js'

const Container = styled.div`
  ${tw`relative text-gray-100 bg-left`}
`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`

const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
])

const Image = styled.img`
  ${tw`-ml-12`}
  min-width: 80%;
`

const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Subheading = tw(SubheadingBase)`text-center md:text-left text-gray-100`
const Heading = tw(SectionHeading)`mt-4 text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left`
const Description = tw(SectionDescription)`text-center md:text-left text-sm md:text-base lg:text-lg leading-relaxed`

// eslint-disable-next-line
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
])

// eslint-disable-next-line
export default ({
  subheading = 'Our Expertise',
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  primaryButtonText = 'Learn More',
  primaryButtonUrl = 'https://timerse.com',
  imageSrc = FeaturedImage,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container id="how-it-works">
      <TwoColumn>
        <ImageColumn>
          <Image
            css={imageCss}
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>
              Wrapped KFX, also referred as wKFX, is KnoxFS's primary utility token.
              All KnoxFS future software solutions will include a built-in crypto wallet. This enables users to receive
              and pay with the wKFX token. Paying for storage plans with wKFX is not
              mandatory, but KnoxFS does give users a discount when they pay with wKFX,
              instead of fiat (creditcard, Paypal) or bitcoin.
            </Description>
            <Description>
              Users do not require to have other
              crypto (storage) tokens in their wallet, even if they store data on for instance
              the Sia or Storj network. The wKFX token can also be used to reward users and
              to incentivize other KFX apps. In addition, the token can be traded on
              decentralized exchanges.
            </Description>
            {/* <PrimaryButton
              buttonRounded={buttonRounded}
              as="a"
              href={primaryButtonUrl}
            >
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}
