import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
//eslint-disable-next-line
import { css } from 'styled-components/macro'
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js'
import { SectionDescription } from 'components/misc/Typography.js'

import Icon01 from 'images/icon-01.svg'
import Icon011 from 'images/icon-011.svg'
import Icon02 from 'images/icon-02.svg'
import Icon033 from 'images/icon-033.svg'
import Icon044 from 'images/icon-044.svg'
import Icon03 from 'images/icon-03.svg'
import Icon022 from 'images/icon-022.svg'
import sectionBgImage from 'images/section-02-bg.svg'

const Container = styled.div`
  ${tw`relative bg-contain bg-center min-h-screen -mx-8 px-8 text-gray-100 bg-no-repeat`}
  background-image: url("${sectionBgImage}");
`

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`
const Heading = tw(SectionHeading)`w-full`
const Description = tw(SectionDescription)`w-full text-center`

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`text-center p-2 flex-shrink-0`}
    img {
      ${tw`w-16 h-16`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`

// eslint-disable-next-line
export default ({
  cards = null,
  heading = 'Your data and its security are paramount',
  subheading = null,
  description = "KnoxFS is a Bitcoin-based cryptocurrency with a focus on decentralized data storage, distribution, privacy, and security."
}) => {
  const defaultCards = [
    {
      imageSrc: Icon044,
      title: 'Few options for participation',
      description:
        "You can use all the storage resources on multiple networks, without having to own all these different coins as payment now."
    },
    {
      imageSrc: Icon03,
      title: 'Powerful and proven encryption methods',
      description:
        "With upcoming decentralized storage, knoxfs priority is data security- on both a personal and corporate level. We're using the highest standard with efficiency in mind to cover this area of operations."
    },
    {
      imageSrc: Icon022,
      title: 'A decentralized solution and distributed network',
      description:
        "Other like-minded individuals who value their data's security and the ability to share at their discretion exist! We're not building out a corporate network or a centralized solution that can be disrupted and destroyed at any given point in time."
    },
    {
      imageSrc: Icon01,
      title: 'Earn KFX passively by supporting the network',
      description:
        "You can stake your KFX to earn more coins, or run a masternode to earn even more passive income and support the network."
    },
    {
      imageSrc: Icon011,
      title: 'Keep your data secure with various options',
      description:
        "In the KnoxFS client you'll be presented with different methods and permissions for sharing and encrypting your data. We're offering powerful tools in an easy-to-use client to be as fault-tolerant and as least error-prone as possible to serve your needs best."
    },
    {
      imageSrc: Icon033,
      title: 'Distributed networks are the future for a reason',
      description:
        "Relying on the old infrastructures that are housed, owned and operated by single entitities was never the true vision of the internet. Nodes all communicating with one another and sharing information was. Using centralized services or providers to store or transmit your data opens you up to massive vulnerabilities."
    }
  ]

  if (!cards) cards = defaultCards

  return (
    <Container id="what-is-knoxfs">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || 'Fully Secure'}</span>
                <p className="description">{card.description}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  )
}
